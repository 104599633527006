.progress-bar-container {
  flex-grow: 1;
  margin-right: 10px;
}

.progress-bar-container span {
  position: absolute;
}

.progress-bar-max-label {
  color: rgba(255, 255, 255, 0.75);
}
